import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import Loader from "./common/assets/image/appModern/loader.svg";

const App = lazy(() => import("./App"));

const MainApp = () => (
  <Suspense
    fallback={
      <div className="loader-container">
        <img src={Loader} alt="Loader" />
      </div>
    }>
    <App />
  </Suspense>
);

ReactDOM.render(<MainApp />, document.getElementById("root"));
